import { useState, Dispatch, SetStateAction } from 'react';
import { SanityCategory, SanityFaq } from '../../graphql-types';
import { sluggify } from '../lib/helpers';

const getCategories = (array: SanityFaq[]): SanityCategory[] =>
  array.reduce((acc, curr) => {
    const category = curr.categories[0];
    if (!category) {
      console.warn('Category is undefined: ' + curr.heading);
      return acc;
    }

    const exists = acc.some((i: SanityCategory) => i.title === category.title);

    if (acc.length === 0 || !exists) {
      acc.push(category);
    }
    return acc;
  }, []);

const getCategoryContent = (
  items: SanityFaq[],
  category: SanityCategory
): SanityFaq[] => {
  return items.filter((item: SanityFaq) =>
    item.categories.some((z: SanityFaq) => z.id === category.id)
  );
};

interface UseFaqResult {
  activeCategory: SanityCategory;
  activeItem: SanityFaq;
  activeItemHash: string;
  categories: SanityCategory[];
  categoryContent: SanityFaq[];
  expandedItems: string[];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  findItemByHash: (hash: string) => SanityFaq;
  hash: string;
  setActiveCategory: Dispatch<SetStateAction<SanityCategory>>;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toggleItem: (item: string) => void;
  getCategoryContent: any;
  setCategoryContent: any;
  updateHash: any;
}

const hashify = (heading: string): string => {
  const slug = sluggify(heading);
  return `#${slug}`;
};

const updateHash = (hash: string) => {
  window.location.hash = hash;
};

export const useFaq = (faqItems: SanityFaq[], hash?: string): UseFaqResult => {
  const categories = getCategories(faqItems);
  const [activeCategory, setActiveCategory] = useState<SanityCategory>(
    categories[0]
  );
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [activeItem, setActiveItem] = useState<SanityFaq>();
  const activeItemHash = activeItem ? hashify(activeItem.heading) : '';
  const initialCategoryContent = getCategoryContent(faqItems, activeCategory);
  const [categoryContent, setCategoryContent] = useState(
    initialCategoryContent
  );

  const findItemById = (id: string) => {
    return faqItems.find(i => i.id === id);
  };

  const findItemByHash = (hash: string) => {
    return faqItems.find(i => hashify(i.heading) === hash);
  };

  const toggleItem = (itemId: string): void => {
    // set active item so the hash can get updated
    const faqItem = findItemById(itemId);
    setActiveItem(faqItem);
    // close/open item
    if (expandedItems.includes(itemId)) {
      setExpandedItems(prevState => prevState.filter(i => i !== itemId));
      return;
    } else {
      setExpandedItems(prevState => [...prevState, itemId]);
      setActiveCategory(faqItem.categories[0]);
    }
    return;
  };

  return {
    activeCategory,
    activeItem,
    activeItemHash,
    categories,
    categoryContent,
    expandedItems,
    findItemByHash,
    hash,
    setActiveCategory,
    toggleItem,
    getCategoryContent,
    setCategoryContent,
    updateHash
  };
};
