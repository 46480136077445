import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { WindowLocation } from '@reach/router';
import { Collapsible } from '../redesign/organisms/collapsible/collapsible';
import { Column, Row, Layout, Module, TextLockup, Text } from '../redesign';
import { FaqSidebar } from '../redesign/organisms/faq-sidebar/faq-sidebar';
import { SanityFaqList } from '../../graphql-types';
import { useFaq } from '../hooks/use-faq';

interface FaqPageProps {
  location: WindowLocation;
  data: {
    sanityFaqList: SanityFaqList;
  };
}

const FaqPage: React.FC<FaqPageProps> = ({ location, data }) => {
  const hash = location.hash;
  const items = data.sanityFaqList.items;
  const {
    activeCategory,
    categories,
    categoryContent,
    expandedItems,
    setActiveCategory,
    toggleItem,
    getCategoryContent,
    setCategoryContent,
    updateHash,
    activeItemHash,
    findItemByHash
  } = useFaq(items, hash);

  useEffect(() => {
    const el = document.getElementById(`${hash.replace('#', '')}-container`);
    const itemId = findItemByHash(hash)?.id;
    if (itemId && hash !== '') {
      toggleItem(itemId);
    }
    const timeOutId = setTimeout(() => {
      el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 1500); // wait a bit, then scroll into view for a smooth animation
    return () => clearTimeout(timeOutId);

    // intentionally omitting hash from deps because we want this effect to run only the first time the page is rendered
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Update category content
    const newContent = getCategoryContent(items, activeCategory);
    setCategoryContent(newContent);
  }, [activeCategory, getCategoryContent, items, setCategoryContent]);

  useEffect(() => {
    updateHash(activeItemHash);
  }, [activeItemHash, updateHash]);

  return (
    <Layout>
      <Module>
        <TextLockup alignment="center" className="mt-lg mb-md">
          <Text variant="p3b" className="text-lockup-eyebrow">
            Frequently Asked Questions
          </Text>

          <Text
            variant="h1a"
            color="dark-green"
            className="text-lockup-headline mb-md"
          >
            Find the answers you're looking for
          </Text>
        </TextLockup>
      </Module>
      <Row
        className="sidebar-container"
        gap={0}
        borders={['bottom', 'top bottom']}
      >
        <Column cols={[25, 7]} borders={['', 'right']}>
          <FaqSidebar
            activeCategory={activeCategory}
            categories={categories}
            heading="CHOOSE SECTION"
            setActiveCategory={setActiveCategory}
          />
        </Column>
        <Column
          cols={[25, 16]}
          className="sidebar-content"
          borders={['right', 'right']}
        >
          <Collapsible
            dataArray={categoryContent}
            initialExpanded={1}
            expandedItems={expandedItems}
            toggleItem={e => toggleItem(e)}
          />
        </Column>
      </Row>
    </Layout>
  );
};

export default FaqPage;

export const query = graphql`
  query FaqPageQuery {
    sanityFaqList(sanityTitle: { eq: "FAQ page" }) {
      items {
        id
        heading
        _rawBody
        categories {
          id
          title
        }
      }
    }
  }
`;
