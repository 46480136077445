import React, { Dispatch, SetStateAction } from 'react';
import { SanityCategory } from '../../../../graphql-types';
import { Text } from '../../';
import './faq-sidebar.scss';

interface FaqSidebarProps {
  activeCategory: SanityCategory;
  categories: SanityCategory[];
  heading: string;
  setActiveCategory: Dispatch<SetStateAction<SanityCategory>>;
}

export const FaqSidebar: React.FC<FaqSidebarProps> = ({
  activeCategory,
  categories,
  heading,
  setActiveCategory
}) => {
  return (
    <>
      <Text variant="u3a" color="dark-green" className="sidebar-headline">
        {heading}
      </Text>
      <div className="sidebar-items-container">
        {categories.map((category: SanityCategory) => {
          return (
            <Text
              color="dark-green"
              variant="p3a"
              className={
                activeCategory.id === category.id
                  ? 'sidebar-items sidebar-active'
                  : 'sidebar-items'
              }
              key={category.id}
              onClick={() => setActiveCategory(category)}
            >
              {category.title}
            </Text>
          );
        })}
      </div>
    </>
  );
};
